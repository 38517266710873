import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M31 0v19.537C31 26.42 25.42 32 18.537 32H1V12.463C1 5.58 6.58 0 13.463 0zm-1 1H13.463C10.4 1 7.523 2.192 5.357 4.357A11.388 11.388 0 0 0 2 12.463V31h16.537c3.062 0 5.94-1.192 8.106-3.357A11.388 11.388 0 0 0 30 19.537zM18.138 6c4.264 0 7.733 3.47 7.733 7.733 0 4.264-3.469 7.733-7.733 7.733a7.69 7.69 0 0 1-4.47-1.426l-6.314 6.314-.708-.708 6.242-6.241a7.713 7.713 0 0 1-2.483-5.672c0-4.264 3.47-7.733 7.733-7.733m0 1a6.74 6.74 0 0 0-6.733 6.733 6.74 6.74 0 0 0 6.733 6.733 6.74 6.74 0 0 0 6.733-6.733A6.74 6.74 0 0 0 18.138 7" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;