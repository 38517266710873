import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckmark32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="checkmark-32_svg__a" d="m26.89 7.293 1.415 1.414L13.19 23.835c-1.272 1.212-2.925 1.177-4.218 0l-5.674-5.588 1.404-1.425 5.645 5.56c.508.463.972.473 1.446.022z" /></defs><g fill="none" fillRule="evenodd"><mask id="checkmark-32_svg__b" fill="#fff"><use xlinkHref="#checkmark-32_svg__a" /></mask><g fill="#000" mask="url(#checkmark-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgCheckmark32);
export default Memo;