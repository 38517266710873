import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="service-32_svg__a" d="M31 0v19.537C31 26.42 25.42 32 18.537 32H1V12.463C1 5.58 6.58 0 13.463 0zm-1 1H13.463C10.4 1 7.523 2.192 5.357 4.357A11.388 11.388 0 0 0 2 12.463V31h16.537c3.062 0 5.94-1.192 8.106-3.357A11.388 11.388 0 0 0 30 19.537zM18.35 12.995v11.054A1.951 1.951 0 0 1 16.397 26h-2.746V14.946c0-1.077.874-1.951 1.952-1.951zm-1 1h-1.746a.952.952 0 0 0-.952.951V25h1.746a.952.952 0 0 0 .952-.951zm1-7.995v3.06a1.951 1.951 0 0 1-1.952 1.95h-2.746V7.951c0-1.077.874-1.95 1.952-1.95zm-1 1h-1.746a.952.952 0 0 0-.952.951v2.06h1.746a.952.952 0 0 0 .952-.952z" /></defs><g fill="none" fillRule="evenodd"><mask id="service-32_svg__b" fill="#fff"><use xlinkHref="#service-32_svg__a" /></mask><g fill="#000" mask="url(#service-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;