import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCancel32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M23.372 8 16 15.372 8.628 8 8 8.628 15.372 16 8 23.372l.628.628L16 16.628 23.372 24l.628-.628L16.628 16 24 8.628z" /></SvgIcon>;
};
const Memo = memo(SvgCancel32);
export default Memo;