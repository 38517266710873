import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 600 600" sx={{
    ...sx
  }} {...other}><path d="M0 0h600v600H0z" /><path d="M375 75v298.47c0 14.39-.35 26.71-1.06 36.94-.71 10.24-3.3 19.71-7.75 28.43-6.69 12.57-15.86 21.59-27.52 27.06-11.66 5.47-23.16 8.21-34.51 8.21H167.05v42.87h137.41c21.48 0 41.1-4.56 58.83-13.68 17.74-9.12 31.87-22.9 42.42-41.35 5.27-9.12 8.86-18.19 10.79-27.21 1.92-9.02 2.99-18.44 3.19-28.28.2-9.83.3-20.83.3-32.99V75z" style={{
      fill: "#fff"
    }} /></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;