import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="user-32_svg__a" d="M30 19.537c0 3.062-1.192 5.94-3.357 8.106a11.576 11.576 0 0 1-1.573 1.317c-1.373-.698-2.64-1.354-4.089-2.07-1.637-.806-1.17-3.162-.261-4.4.494-.67.758-1.511 1.14-2.269.599-.805 1.383-1.008 1.392-2.232.02-.817.042-1.638-.014-2.452-.046-.569-.444-1.119-.517-1.689-.013-1.008-.003-1.262.002-2.27.072-3.684-3.283-5.55-6.66-5.577H16c-3.376.027-6.731 1.893-6.66 5.577.005 1.008.015 1.262.002 2.27-.073.57-.47 1.12-.517 1.69-.056.813-.035 1.634-.014 2.451.01 1.224.794 1.427 1.393 2.232.381.758.645 1.599 1.14 2.27.908 1.237 1.375 3.593-.262 4.4-1.377.68-6.1 2.753-9.082 4.108V12.463C2 9.4 3.192 6.523 5.357 4.357A11.388 11.388 0 0 1 13.463 1H30zM4.465 31c.514-.23 1.15-.509 1.674-.743 2.316-1.035 4.504-2.013 5.394-2.452.798-.394 1.335-1.07 1.551-1.954.344-1.405-.213-3.003-.92-3.965-.26-.353-.458-.808-.667-1.29a16.252 16.252 0 0 0-.383-.833l-.04-.08-.052-.07a5.682 5.682 0 0 0-.694-.751c-.38-.359-.494-.467-.497-.88v-.031c-.021-.81-.041-1.576.01-2.336.013-.138.103-.356.19-.567.126-.311.27-.663.322-1.07l.008-.058v-.058c.01-.736.007-1.075.003-1.588l-.004-.7v-.016c-.022-1.128.343-2.088 1.086-2.852 1.013-1.04 2.718-1.67 4.562-1.686h.047c1.844.015 3.549.645 4.562 1.686.743.764 1.108 1.724 1.086 2.852v.015l-.004.701c-.004.513-.007.852.003 1.588v.058l.008.057c.052.408.196.76.323 1.07.086.212.176.43.189.568.051.76.032 1.525.01 2.336v.03c-.003.414-.117.522-.497.88-.203.194-.457.433-.694.752l-.052.07-.04.08c-.141.28-.264.561-.382.834-.21.481-.408.936-.67 1.29-.704.961-1.262 2.56-.918 3.964.216.885.753 1.56 1.55 1.953 1.032.51 1.952.98 2.926 1.477l.602.307A11.393 11.393 0 0 1 18.537 31zm8.998-31C6.58 0 1 5.58 1 12.463V32h17.537C25.42 32 31 26.42 31 19.537V0z" /></defs><g fill="none" fillRule="evenodd"><mask id="user-32_svg__b" fill="#fff"><use xlinkHref="#user-32_svg__a" /></mask><g fill="#000" mask="url(#user-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;