import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="cart-32_svg__a" d="M30.951 0v19.558C30.951 26.429 25.381 32 18.51 32H1V12.443C1 5.57 6.57 0 13.443 0zm-.998 3.994h-16.51c-3.057 0-5.931 1.19-8.093 3.351a11.369 11.369 0 0 0-3.352 8.092V30.95h16.51c3.057 0 5.931-1.19 8.093-3.352a11.369 11.369 0 0 0 3.352-8.092zm-18.97 4.991a5.997 5.997 0 0 0 5.99 5.99 5.997 5.997 0 0 0 5.991-5.99h.998a6.997 6.997 0 0 1-6.988 6.989 6.997 6.997 0 0 1-6.989-6.989ZM29.954.998h-16.51c-3.057 0-5.931 1.19-8.093 3.352a11.367 11.367 0 0 0-3.084 5.614c2.026-4.127 6.269-6.969 11.177-6.969h16.51z" /></defs><g fillRule="evenodd"><mask id="cart-32_svg__b" fill="#fff"><use xlinkHref="#cart-32_svg__a" /></mask><use xlinkHref="#cart-32_svg__a" /><g mask="url(#cart-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;