import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="newsletter-32_svg__a" d="M31 0v19.537C31 26.42 25.42 32 18.537 32H1V12.463C1 5.58 6.58 0 13.463 0zm-1 1H13.463a11.39 11.39 0 0 0-8.106 3.357A11.388 11.388 0 0 0 2 12.463V31h16.537a11.39 11.39 0 0 0 8.106-3.357A11.388 11.388 0 0 0 30 19.537zM16 6.663l5.907 3.482h2.02v1.191l1.637.965v13.037H6.437V12.302l1.636-.965v-1.191h2.019zm-8.563 7.514v10.16h17.127v-10.15l-5.835 3.672 4.862 4.862-.707.707-5.023-5.023-.702.442c-.328.206-.747.309-1.167.309-.421 0-.843-.104-1.171-.311l-.7-.443-5.026 5.025-.707-.707 4.866-4.866zm15.49-3.031H9.073v2.883l6.282 3.97c.332.21.938.211 1.27.002l6.302-3.965zm1 1.351v.91l.636-.401v-.133zm-15.854 0-.636.376v.12l.636.403zM16 7.823l-3.939 2.322h7.878z" /></defs><g fill="none" fillRule="evenodd"><mask id="newsletter-32_svg__b" fill="#fff"><use xlinkHref="#newsletter-32_svg__a" /></mask><g fill="#000" mask="url(#newsletter-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;