import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="wishlist-32_svg__a" d="M31 0v19.537C31 26.42 25.42 32 18.537 32H1V12.463C1 5.58 6.58 0 13.463 0zm-1 1H13.463C10.4 1 7.523 2.192 5.357 4.357A11.388 11.388 0 0 0 2 12.463V31h16.537c3.062 0 5.94-1.192 8.106-3.357A11.388 11.388 0 0 0 30 19.537zm-8.94 7c4.2 0 6.558 5.47 3.846 9.147-.396.54-1.067 1.372-1.903 2.357C21.115 21.731 18.381 24.754 16 27c-2.381-2.246-5.12-5.27-7.003-7.496-.836-.985-1.507-1.816-1.903-2.357C4.38 13.47 6.739 8 10.94 8c1.5 0 3.236.698 5.06 2.424C17.824 8.697 19.559 8 21.06 8m0 1c-1.375 0-2.886.744-4.373 2.15L16 11.8l-.687-.65C13.826 9.744 12.315 9 10.94 9c-.74 0-1.424.211-2.035.627a4.387 4.387 0 0 0-1.42 1.658 5.156 5.156 0 0 0-.543 2.617c.056.962.386 1.879.956 2.651.38.518 1.04 1.335 1.861 2.304 1.617 1.91 4.03 4.596 6.241 6.757 2.736-2.67 5.431-5.802 6.24-6.757.823-.969 1.483-1.786 1.86-2.3a4.934 4.934 0 0 0 .957-2.655 5.156 5.156 0 0 0-.542-2.617 4.388 4.388 0 0 0-1.42-1.658A3.551 3.551 0 0 0 21.06 9" /></defs><g fill="none" fillRule="evenodd"><mask id="wishlist-32_svg__b" fill="#fff"><use xlinkHref="#wishlist-32_svg__a" /></mask><g fill="#000" mask="url(#wishlist-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;